import './anchor.scss';
import { getOffset, smoothScrollTo } from '../../js/helper';

class Anchor {
    constructor (elements) {
        this.$anchors = elements;
        this.waiting = false;
    }

    initialize () {
        this.setEvents();
    }

    setEvents () {
        for (let i = 0; i < this.$anchors.length; i++) {
            this.$anchors[i].addEventListener('click', (e) => {
                e.preventDefault();
                const $target = document.querySelector(this.$anchors[i].getAttribute('href'));

                smoothScrollTo(0, getOffset($target).top, 500);
            });
        }
    }
}

export { Anchor };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $anchor = $context.querySelectorAll('[data-anchor="scroll"]');

        for (let i = 0; i < $anchor.length; i++) {
            const $$anchor = new Anchor($anchor[i]);
            $$anchor.initialize();
        }
    }
});
